.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a { 
    text-decoration: none;
    color: black;
}

.color-red {
  color: red;

}

.color-blue{
  color: #0073b1;
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.form-control-dark {
  color: #fff;
  background-color: var(--bs-dark);
  border-color: var(--bs-gray);
}
.form-control-dark:focus {
  color: #fff;
  background-color: var(--bs-dark);
  border-color: #fff;
  box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.text-small {
  font-size: 85%;
}

.dropdown-toggle {
  outline: 0;
}

body,
html {
  height: 4000px;
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.scrollablediv {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.bg {
  background-image: url('../public/imagebk.webp');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.cardFlow {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content:center;
  align-content: flex-start

  
}

.cardFlexItem {
  max-width: 26rem;
  margin-bottom: 2rem;
  background-color: #f4f1f1;
  border: none;
}

.flex-container {
  /* We first create a flex layout context */
  display: flex;
  
  /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-flow: row wrap;
  
  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
  
  padding: 0;
  margin: 0;
  list-style: none;
}


.fontStyleH1 {
  font-size: 4rem;
  color: darkgrey;
  text-align: left;
  margin: 1rem 0 1rem 1rem;
}

.separator {
  margin: 1rem 0 2rem 1rem;
  border: none;
  height: 1rem;
  background-color: rgb(173, 165, 5);
  width: 15rem;
}

.about {
  margin: 1rem 1rem 2rem 1rem;
  border: none;
  background-color: #f4f1f1;
  text-align: justify;
}

.contact{
  display: flex;
}

.contactusForm{
  text-align: left;
  margin-left: 1rem;
}

.cardFlexItemApplyJob {
  width: -webkit-fill-available;
  border: none;
  max-width: 39rem;
  text-align: left;
}

.cardFlexItemContactAddress {
  width: -webkit-fill-available;
  margin-bottom: 2rem;
  background-color: #f4f1f1;
  border: none;
  max-width: 38rem;
  justify-content: center;
}

.backgroundColor{
  background-color: #f4f1f1;
}

.applyJobTextArea{
  padding-bottom: 2rem;
  text-align: left;
}

.nav-link{
  font-family: 'Segoe UI', sans-serif;
  color: #000000 !important;
  font-size: xx-large;
}

.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.cardFlexItemJob {
  width: -webkit-fill-available;
  margin-bottom: 2rem;
  background-color: #f4f1f1;
  border: none;
  max-width: 38rem;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.introTextPosition{
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 40%; /* Adjust this value to move the positioned div up and down */
  text-align: center;
  width: 60%; /* Set the width of the positioned div */
  color: white;
  box-shadow: 0 0 0 #000;
}

.companyName{
  font-size: calc(10px + 2vmin);
}

.companyTagLine{
  font-size: calc(10px + 2vmin);
}

.companyArrowDownLogo{
  color: white;
  font-size: 2.5rem;
  color: #fff;
}

.textGlow{
  color: #fff;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px rgb(13, 5, 100),
    0 0 42px rgb(1, 13, 118),
    0 0 82px rgb(1, 13, 118),
    0 0 92px rgb(1, 13, 118),
    0 0 102px rgb(1, 13, 118),
    0 0 151px rgb(1, 13, 118);
}